import '@fontsource/ubuntu/400.css'
import '@fontsource/ubuntu/500.css'
import '@fontsource/ubuntu/700.css'
import 'Shared/sass/style.scss'
import './src/sass/style.scss'

import {
  checkHash,
  isBrowser,
  scroller,
} from 'Shared/utils/PuxAnchorLink/puxAnchorUtils'
import { removeAllParamsWhenUTM } from 'Shared/utils/removeUTM'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onClientEntry = () => {
  if (document.querySelector(`.pux-notfound`)) {
    removeAllParamsWhenUTM(window.location.href)
  }
}

export const onRouteUpdate = ({ location }) => {
  let windowHash
  let offset = -50
  let duration = 800

  if (isBrowser) {
    window.gatsby_scroll_offset = offset
    window.gatsby_scroll_duration = duration
    windowHash = window.gatsby_scroll_hash
  }

  windowHash ? scroller(windowHash, offset) : checkHash(location, offset)

  if (isBrowser && windowHash) {
    window.gatsby_scroll_hash = undefined
  }
}
